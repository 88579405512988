import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HomeBanner from '../components/homebanner'
import HomeResources from '../components/homeresources'
import HomeContent from '../components/homecontent'

const IndexPage = () => (
  <Layout location="home">
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <HomeBanner />
    <HomeResources />
    <HomeContent />
  </Layout>
)

export default IndexPage
