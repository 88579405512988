import React from 'react'
import { Container, Grid, Card } from 'semantic-ui-react'

import imgCard1 from "../images/parent.png"
import imgCard2 from "../images/JEF_RG_Teachers.png"
import imgCard3 from "../images/JEF_RG_Teachers2.png"
import imgCard4 from "../images/hallway.png"

const HomeResources = () => (
    <Container className="home-resources">
        <Grid>
            <Grid.Row columns="4">
                <Grid.Column>
                    <Card image={imgCard1}
                        header='For Parents'
                        description='Understanding the unique challenges of your child' 
                    />
                </Grid.Column>
                <Grid.Column>
                    <Card image={imgCard2}
                        header='For Educators'
                        description='Understanding how to develop a creative and flexibile learning environment' 
                    />
                </Grid.Column>
                <Grid.Column>
                    <Card image={imgCard3}
                        header='For Kids'
                        description='Understanding how to include those who may be different' 
                    />
                </Grid.Column>
                <Grid.Column>
                    <Card image={imgCard4}
                        header='For Friends'
                        description='Understanding how to support friends with Tourette Syndrome' 
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Container>
)

export default HomeResources