import PropTypes from 'prop-types'
import React from 'react'
import { Segment, Container, Header, Button, Icon } from 'semantic-ui-react'

const HomeBanner = ({ mobile }) => (
    <Segment
            inverted
            textAlign='center'
            vertical
            className="masthead"
          >
        <Container text>
            <Header
                as='h1'
                content='Our Mission'
                inverted
            />
            <Header
                as='h2'
                content='To find success for every child with Tourette Syndrome'
                inverted
            />
            <Button primary size='huge' as='a' href='/get-connected/'>
                Learn More
                <Icon name='right arrow' />
            </Button>
        </Container>
    </Segment>
  )
  
  HomeBanner.propTypes = {
    mobile: PropTypes.bool,
  }

  export default HomeBanner