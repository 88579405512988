import React from 'react'
import { Container, Grid, Image } from 'semantic-ui-react'
import imgJim from "../images/jim_philly1.jpg"

const HomeContent = () => (
    <Container style={{ marginBottom: '35px' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={10}>
            <p style={{ fontSize: '1.33em' }}>"I believe that every child who has Tourette Syndrome can find personal success in their life. With the support and encouragement of their parents, families, teachers, friends and doctors, there is no limit to what a child with Tourette can achieve.</p>
            <p style={{ fontSize: '1.33em' }}>I encourage you to take advantage of the resources available on our site and through other TS organizations to make your journey more rewarding."</p>
            <p style={{ fontSize: '1.33em', fontWeight: 'bold', textAlign: 'right' }}>-Jim Eisenreich</p>
          </Grid.Column>
          <Grid.Column floated='right' width={4}>
            <Image bordered rounded size='medium' src={imgJim} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
)

export default HomeContent